import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import Layout from "../../layout"
import config from "../../../data/SiteConfig"
import Img from "gatsby-image"
import "./newsItem.sass"
import NewsListing from "../../components/NewsListing/NewsListing";
import BetSiteListing from "../../components/BetSiteListing/BetSiteListing";
import BetBonusListing from "../../components/BetBonusListing/BetBonusListing";
import PageShare from "../../components/PageShare/PageShare";

export default class NewsItem extends React.Component {
  render() {
    let newsItem = this.props.data.prismicNewsItem.data
    let uid = this.props.data.prismicNewsItem.uid
    let relatedNews = this.props.data.allPrismicNewsItem.edges
    const betSites = this.props.data.allPrismicBetsite.edges
    let url = "https://esportstips.net/news/" + uid
    // const mainTag = this.props.data.prismicNewsItem.tags[0]
    return (
      <Layout>
        <div>
            <Helmet>
                <title>{`${newsItem.metatitle.text}`}</title>
                <meta name="description" content={newsItem.description.text} />
                <meta name="image" content={newsItem.image.url} />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <script type="application/ld+json">
                  {JSON.stringify(
                    {
                      "@context": "http://schema.org",
                      "@type": "NewsArticle",
                      "headline": newsItem.title.text,
                      "alternativeHeadline": newsItem.metatitle.text,
                      "dateline": "New York, USA",
                      "image": [
                        newsItem.image.url
                      ],
                      "datePublished": newsItem.date,
                      "description": newsItem.description.text,
                      "articleBody": newsItem.content.text
                    }
                  )}
                </script>
                <meta property="og:url" content={this.props.location.href} />
                <meta property="og:title" content={newsItem.metatitle.text} />
                <meta property="og:description" content={newsItem.description.text} />
                <meta property="og:image" content={newsItem.image.url} />
                {/* <meta property="fb:app_id" content={config.siteFBAppID ? config.siteFBAppID : ""}/> */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content={config.userTwitter ? config.userTwitter : ""}/>
                <meta name="twitter:title" content={newsItem.metatitle.text} />
                <meta name="twitter:description" content={newsItem.description.text} />
                <meta name="twitter:image" content={newsItem.image.url} />
            </Helmet>
          {/* <section className="hero">
            <div className="hero-body">
              <div className="container">
                <h2 className="title">
                  { newsItem.title.text }
                </h2>
                <h3 className="subtitle">
                  { newsItem.description.text }
                </h3>
              </div>
            </div>
          </section> */}
          <section className="section">
            <div className="container">
            <div className="columns">
              <div className="column">
                  <div className="news-image">
                    <Img fluid={ newsItem.image.localFile.childImageSharp.fluid } alt={ uid }/>
                    <div className="bg-tint-4"></div>
                    <span className="tag is-info news-author-tag">By { newsItem.author }</span>
                    {/* <Link to={'/' + mainTag}> */}
                      <span className="tag is-light news-key-tag">{ newsItem.key_tag }</span>
                    {/* </Link> */}
                  </div>

                  <div className="section content-section">
                    <h1 className="title news-title">
                      { newsItem.title.text }
                    </h1>

                    <p dangerouslySetInnerHTML={{ __html: newsItem.content.html }} />
                    {/* <span className="tag is-info news-author-tag">By { newsItem.author }</span> */}
                    <hr></hr>
                    <div className="level">
                      <span className="tag is-dark news-date-tag">Published { newsItem.date }</span>
                    </div>
                    <PageShare
                      url={url}
                      title={newsItem.title.text}
                      description={newsItem.description.text}
                      size="38">
                    </PageShare>
                  </div>
              </div>
              <div className="column is-one-third">
                <BetSiteListing sites={betSites} className="bet-site-listing" />
              </div>
            </div>

              {/* <div className="news-box">
              </div> */}
            </div>
          </section>
          <section className="section content-section">
            <div className="container">
              <h4 className="subtitle">Related news</h4>
              <NewsListing news={relatedNews}/>
            </div>
          </section>
          <section className="section">
            <div className="container">
              <h4 className="subtitle">Claim your esports betting bonus</h4>
              <BetBonusListing sites={betSites} className="bet-site-listing" />
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query NewsItemBySlug($slug: String!) {
    prismicNewsItem(uid: {eq: $slug}) {
        uid
        # tags
        data {
          title {
            text
          }
          metatitle {
            text
          }
          description {
            text
          }
          author
          date
          key_tag
          content {
            html
            text
          }
          image {
            url
            localFile {
              childImageSharp {
                fluid(maxWidth: 800, maxHeight: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
    }
    allPrismicNewsItem (
      limit: 4
      filter: {uid: {ne: $slug}}
    	sort: {
      	fields: [data___date]
      	order: DESC
  		}) {
        edges {
          node {
            uid
            data {
              title {
                text
              }
              key_tag
              date
              description {
                html
              }
              image {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
      allPrismicBetsite(limit: 3) {
        edges {
          node {
            uid
            data {
              name {
                text
              }
              website {
                url
              }
              terms_and_conditions_link {
                url
              }
              aff_link {
                url
              }
              bonus
              brand_color
              logo {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
  }
`;

